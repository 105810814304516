// import axios from "axios"
import { config } from "@/config"
import Vue from "vue"

/**
 * Create a Base API wrapper around axios.
 */
export class BaseApi {
  version
  baseUrl
  baseDomain
  api
  apiConfig
  type

  constructor (
    {
      version = process.env.api_version,
      baseDomain = process.env.api_url + "/" + "v1",
      apiConfig = {
        baseURl: this.baseUrl,
        timeout: 300000, // 2 min
        headers: {
          "x-purge": true
        },
      },
      type = "account",
    } = {
        version: process.env.api_version,
        baseDomain: process.env.api_url + "/" + process.env.api_version,
        apiConfig: {
          baseURl: this.baseUrl,
          timeout: 300000, // 2 min
          headers: {
            "x-purge": true
          },
        },
        type: "account",
      }
  ) {
    this.version = version
    // console.log("BaseApi -> process.env", Vue)
    this.baseDomain = baseDomain || `${ process.env.api_url }/${ process.env.api_version }` // `http://api.stage.storagepug.com/${version}`
    // Vue.store
    // Set type
    this.type = type

    if (this.type === "account" || this.type === "component-schema" || this.type === "section-schema") {
      this.buildUrl(this.type)
    }

    if (apiConfig) {
      this.api = axios.create(apiConfig)
    }
  }

  /**
   * Add Api type to baseUrl. For any
   */
  buildUrl(type, accountId) {
    let innerType = type
    let url = this.baseDomain

    if (!type) {
      innerType = this.type
    }

    if (accountId && innerType !== "account") {
      url += `/${ accountId }`
    }

    url += `/${ innerType }`
    this.baseUrl = url
  }

  /**
   * Get all locations
   */
  async all(ids, relations, where, take, sortColumn, sortOrder = "ASC") {
    try {
      let url = this.baseUrl

      let idToken = await firebase.auth().currentUser.getIdToken()
      this.api.defaults.headers.common['Authorization'] = idToken

      if ((this.type !== "account" || this.type !== "section-schema") && ids) {
        this.buildUrl(null, ids)
        url = this.baseUrl
      }

      if (this.type === "account" && ids) {
        url += `?ids=${ ids }`
      }

      if (url.includes("?") && relations) {
        url += `&relations=${ relations }`
      } else if (relations) {
        url += `?relations=${ relations }`
      }

      if (url.includes("?") && where) {
        url += `&where=${ where }`
      } else if (where) {
        url += `?where=${ where }`
      }

      if (url.includes("?") && take) {
        url += `&take=${ take }`
      } else if (take) {
        url += `?take=${ take }`
      }

      if (url.includes("?") && sortColumn) {
        url += `&sortColumn=${ sortColumn }&sortOrder=${ sortOrder }`
      } else if (sortColumn) {
        url += `?sortColumn=${ sortColumn }&sortOrder=${ sortOrder }`
      }

      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * THIS ONLY APPLYS TO THESE ENTITIES
   *
   * Locations
   * Pages
   */
  allDeleted(ids, relations, where, take, sortColumn, sortOrder = "ASC") {
    try {
      let url = this.baseUrl

      if ((this.type !== "account" || this.type !== "section-schema") && ids) {
        this.buildUrl(null, ids)
        url = this.baseUrl
      }
      url = url + "/deleted"

      if (this.type === "account" && ids) {
        url += `?ids=${ ids }`
      }

      if (url.includes("?") && relations) {
        url += `&relations=${ relations }`
      } else if (relations) {
        url += `?relations=${ relations }`
      }

      if (url.includes("?") && where) {
        url += `&where=${ where }`
      } else if (where) {
        url += `?where=${ where }`
      }

      if (url.includes("?") && take) {
        url += `&take=${ take }`
      } else if (take) {
        url += `?take=${ take }`
      }

      if (url.includes("?") && sortColumn) {
        url += `&sortColumn=${ sortColumn }&sortOrder=${ sortOrder }`
      } else if (sortColumn) {
        url += `?sortColumn=${ sortColumn }&sortOrder=${ sortOrder }`
      }

      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Get one item from database
   *
   * @param { String } id - ID of item to get
   * @param { String } accountId - ID of account item belongs to
   * @param { String } relations - Comma separated string value
   */
  async one(id, accountId, relations) {
    try {
      let url = this.baseUrl
      let idToken = await firebase.auth().currentUser.getIdToken()
      this.api.defaults.headers.common['Authorization'] = idToken

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      // Attach item ID to url
      url += `/${ id }`

      if (relations) {
        url += "?relations=" + relations
      }

      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Save item to database
   *
   * @param { Object } object - Object to update database with
   * @param { String } accountId - ID of account
   */
  async create(object, accountId) {
    try {
      let url = this.baseUrl
      let idToken = await firebase.auth().currentUser.getIdToken()
      this.api.defaults.headers.common['Authorization'] = idToken

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      return this.api.post(url, object)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Update one item from database
   *
   * @param { Object } object - Object to update database with
   * @param { String } accountId - ID of account
   * @param { String } relations - Comma separated string value
   */
  async update(object, accountId, relations) {
    try {
      let url = this.baseUrl
      let idToken = await firebase.auth().currentUser.getIdToken()
      this.api.defaults.headers.common['Authorization'] = idToken

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      // Attach item ID to url
      url += `/${ object.id }`

      // Attach Relations
      if (relations) {
        url += "?relations=" + relations
      }

      return this.api.put(url, object)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Delete one item from database
   *
   * @param { String } id - ID of item to delete
   */
  async delete(id, accountId) {
    try {
      let url = this.baseUrl
      let idToken = await firebase.auth().currentUser.getIdToken()
      this.api.defaults.headers.common['Authorization'] = idToken

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      // Attach item ID to url
      url += `/${ id }`

      return this.api.delete(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Delete one item from database
   */
  // batchDelete(body, accountId) {
  //   try {
  //     let url = this.baseUrl

  //     if (this.type !== 'account' && accountId) {
  //       this.buildUrl(null, accountId)
  //       url = this.baseUrl
  //     }

  //     console.log('batchDelete url: ', url)
  //     return this.api.delete(url, { data: body })
  //   } catch (error) {
  //     Vue.rollbar.error(error)
  //     return error
  //   }
  // }
}

// export class BaseApi {
//   version
//   baseUrl
//   baseDomain
//   api
//   type

//   constructor (version, baseDomain, apiConfig, type) {
//     this.version = 'v1' || version
//     this.baseDomain = `http://localhost:5000/${this.version}` || baseDomain // `http://api.stage.storagepug.com/${version}`

//     // Set type
//     this.type = 'account' || type

//     if (this.type === 'account') {
//       this.buildUrl(this.type)
//     }

//     if (apiConfig) {
//       this.api = axios.create(apiConfig)
//     } else {
//       this.api = axios.create({
//         baseURl: this.baseUrl,
//         timeout: 5000,
//         headers: {
//           Authorization: `Bearer yourSuppaToken`
//         }
//       })
//     }
//   }

//   /**
//    * Add Api type to baseUrl. For any
//    */
//   buildUrl (type, accountId) {
//     let innerType = type
//     let url = this.baseDomain

//     if (!type) {
//       innerType = this.type
//     }

//     if (accountId && innerType !== 'account') {
//       url += `/${accountId}`
//     }

//     url += `/${innerType}`
//     this.baseUrl = url
//   }

//   /**
//    * Get all locations
//    */
//   all (ids, relations, where = null, skip = null, take = null) {
//     let url = this.baseUrl
//     let hasQuestionMark = false
//     if (this.type !== 'account' && ids) {
//       this.buildUrl(null, ids)
//       url = this.baseUrl
//       hasQuestionMark = true
//     }

//     if (this.type === 'account' && ids) {
//       url += `?ids=${ids}`
//       hasQuestionMark = true
//     }

//     if (this.type === 'account' && relations) {
//       url += `&relations=${relations}`
//     } else if (relations) {
//       url += `?relations=${relations}`
//       hasQuestionMark = true
//     }

//     if (skip !== null && hasQuestionMark === false) {
//       url += `?skip=${skip}`
//     } else if (skip !== null && hasQuestionMark === true) {
//       url += `&skip=${skip}`
//       hasQuestionMark = true
//     }

//     if (take !== null && hasQuestionMark === false) {
//       url += `?take=${take}`
//     } else if (take !== null && hasQuestionMark === true) {
//       url += `&take=${take}`
//       hasQuestionMark = true
//     }

//     return this.api.get(url)
//   }

//   /**
//    * Get one item from database
//    *
//    * @param { String } id - ID of item to get
//    * @param { String } relations - Comma separated string value
//    */
//   one (id, accountId, relations) {
//     try {
//       let url = this.baseUrl

//       if (this.type !== 'account' && accountId) {
//         this.buildUrl(null, accountId)
//         url = this.baseUrl
//       }

//       // Attach item ID to url
//       url += `/${id}`

//       if (relations) {
//         url += '?relations=' + relations
//       }

//       return this.api.get(url)
//     } catch (error) {
//       return error
//     }
//   }

//   /**
//    * Create one item from database
//    *
//    * @param { Object } object - Object to update database with
//    */
//   create (object, accountId) {
//     let url = this.baseUrl

//     if (this.type !== 'account' && accountId) {
//       this.buildUrl(null, accountId)
//       url = this.baseUrl
//     }

//     return this.api.post(url, object)
//   }
//   /**
//    * Update one item from database
//    *
//    * @param { String } id - ID of item to update
//    * @param { Object } object - Object to update database with
//    */
//   update (object, accountId) {
//     let url = this.baseUrl

//     if (this.type !== 'account' && accountId) {
//       this.buildUrl(null, accountId)
//       url = this.baseUrl
//     }

//     // Attach item ID to url
//     url += `/${object.id}`

//     return this.api.put(url, object)
//   }

//   /**
//    * Delete one item from database
//    *
//    * @param { String } id - ID of item to delete
//    */
//   delete (id, accountId) {
//     let url = this.baseUrl

//     if (this.type !== 'account' && accountId) {
//       this.buildUrl(null, accountId)
//       url = this.baseUrl
//     }

//     // Attach item ID to url
//     url += `/${id}`

//     return this.api.delete(url)
//   }
// }
