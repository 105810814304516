import axios from 'axios'
import { BaseApi } from './BaseApi'

/**
 * Create a BrightLocal API wrapper around axios.
 *
 * @todo
 * - Get BrightLocal API key and store it in a .env file
 * - Add the BrightLocal API calls
 */
class BrightLocalApi extends BaseApi {
  constructor () {
    super()
    this.type = 'bright-local'
  }

  buildUrl (accountId, locationId, type) {
    const url =
      this.baseDomain +
      '/bright-local/' +
      accountId +
      '/reviews-location?location_id=' +
      locationId +
      '&type=' +
      type

    return url
  }
  buildAddUrl (accountId, locationId, type) {
    const url =
      this.baseDomain +
      '/bright-local/' +
      accountId +
      '/reviews-location/add-url?location_id=' +
      locationId +
      '&type=' +
      type

    return url
  }

  createBatch (accountId, locationId) {
    const baseUrl = this.buildUrl(accountId, locationId, 'create-batch')

    return this.api.get(baseUrl)
  }

  addUrl (accountId, locationId, url) {
    const baseUrl = this.buildAddUrl(accountId, locationId, 'add-url')

    return this.api.post(baseUrl, {
      url
    })
  }

  submitBatch (accountId, locationId) {
    const baseUrl = this.buildUrl(accountId, locationId, 'commit')

    return this.api.get(baseUrl)
  }

  getResults (accountId, locationId) {
    const baseUrl = this.buildUrl(accountId, locationId, 'get-results')

    return this.api.get(baseUrl)
  }
}

export default new BrightLocalApi()
