import { BaseApi } from './BaseApi'

/**
 * Create an EmailNotification API wrapper around axios.
 */
class EmailNotificationApi extends BaseApi {
  constructor() {
    super()
    this.type = 'email-notification'
  }
}

export default new EmailNotificationApi()
