import { BaseApi } from './BaseApi'

/**
 * Create an EmailNotificationEvent API wrapper around axios.
 */
class EmailNotificationEventApi extends BaseApi {
  constructor() {
    super()
    this.type = 'email-notification-event'
    this.buildUrl(this.type)
  }
}

export default new EmailNotificationEventApi()
