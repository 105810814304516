import axios from 'axios'
import { BaseApi } from './BaseApi'

/**
 * Create a Section API wrapper around axios.
 */
class SchemaTagApi extends BaseApi {
  constructor() {
    super()
    this.type = 'schema-tag'
    this.buildUrl(this.type)
  }
}

export default new SchemaTagApi()
