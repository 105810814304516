import axios from "axios"
import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a Section API wrapper around axios.
 */
class PageSchemaApi extends BaseApi {
  constructor() {
    super()
    this.type = "page-schema"
    this.buildUrl(this.type, null)
  }

  saveCustomPageSchemaApi(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let url = this.baseUrl

        // Attach item ID to url
        url += `/save-custom`
        const file = await toBase64(data.file)
        var params = {
          name: data.name,
          description: data.description,
          id: data.id,
          file,
          file_name: data.file.name,
          tags: data.tags,
          author: data.author
        }
        if (data.pageTitle) {
          params.pageTitle = data.pageTitle
        }

        const res = await axios.post(url, params)
        return resolve(res.data)
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  }
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export default new PageSchemaApi()
