import { BaseApi } from './BaseApi'

/**
 * Create a Row API wrapper around axios.
 */
class RowApi extends BaseApi {
  constructor () {
    super()
    this.type = 'row'
  }
}

export default new RowApi()
