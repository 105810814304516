import { BaseApi } from './BaseApi'

/**
 * Create a website API wrapper around axios.
 */
class UnitApi extends BaseApi {
  constructor () {
    super()
    this.type = 'unit'
  }
}

export default new UnitApi()
