import { BaseApi } from './BaseApi'

/**
 * Create a Media API wrapper around axios.
 */
class MediaApi extends BaseApi {
  constructor() {
    super()
    this.type = 'nav-menu'
  }
}

export default new MediaApi()
