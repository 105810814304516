import { BaseApi } from './BaseApi'

/**
 * Create a Blog API wrapper around axios.
 */
class ReviewsApi extends BaseApi {
  constructor () {
    super()
    this.type = 'reviews'
  }
}

export default new ReviewsApi()
