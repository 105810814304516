import axios from "axios"
import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a Section API wrapper around axios.
 */
class ThemeSchemaApi extends BaseApi {
  constructor () {
    super()
    this.type = "theme-schema"
    this.buildUrl(this.type)
  }

  saveCustomThemeSchemaApi(data) {
    return new Promise(async (resolve, reject) => {
      try {
        this.buildUrl(this.type)
        let url = this.baseUrl

        // Attach item ID to url
        url += `/save-custom`
        // const file = await toBase64(data.file)
        // const params = {
        //   name: data.name,
        //   description: data.description,
        //   id: data.id,
        //   file,
        //   file_name: data.file.name,
        //   tags: data.tags,
        // }

        const res = await axios.post(url, data)
        return resolve(res.data)
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  }

  applyCustomThemeSchemaToAWebsite(theme_pages, account_id, theme_id) {
    return new Promise(async (resolve, reject) => {
      try {
        this.buildUrl(this.type)
        let url = this.baseUrl

        // Attach item ID to url
        url += `/apply-custom`
        const params = {
          theme_pages,
          account_id,
          theme_id,
        }

        const res = await axios.post(url, params)
        return resolve(res.data)
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  }
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export default new ThemeSchemaApi()
