import { BaseApi } from './BaseApi'

/**
 * Create a Tag API wrapper around axios.
 */
class HourApi extends BaseApi {
  constructor () {
    super()
    this.type = 'hour'
  }
}

export default new HourApi()
