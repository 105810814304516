import { BaseApi } from './BaseApi'

/**
 * Create a website API wrapper around axios.
 */
class WebsiteApi extends BaseApi {
  constructor () {
    super()
    this.type = 'website'
  }
}

export default new WebsiteApi()
