import { BaseApi } from './BaseApi'

/**
 * Create a Content API wrapper around axios.
 */
class ContentApi extends BaseApi {
  constructor() {
    super()
    this.type = 'content'
  }

  /**
   * Delete multiple items from database
   */
  batchRemove(body, accountId) {
    try {
      let url = this.baseUrl

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      url = url + "-batch-remove"
      return this.api.delete(url, { data: body })
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new ContentApi()
