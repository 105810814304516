import { BaseApi } from './BaseApi'

/**
 * Create a Tag API wrapper around axios.
 */
class TagApi extends BaseApi {
  constructor() {
    super()
    this.type = 'tag'
  }
}

export default new TagApi()
