import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create an ComponentSchema API wrapper around axios.
 */
class ComponentSchemaApi extends BaseApi {
  constructor(opts) {
    super(Object.assign({ type: "component-schema" }, opts))
  }

  /**
   * Update one item from database
   *
   * @param { Object } object - Object to update database with
   * @param { String } accountId - ID of account
   * @param { String } relations - Comma separated string value
   */
  update(object, accountId, relations) {
    try {
      let url = this.baseUrl

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      // Attach item ID to url
      if (object.id) {
        url += `/${object.id}`
      } else {
        url += `/${object.schema.id}`
      }

      // Attach Relations
      if (relations) {
        url += "?relations=" + relations
      }

      return this.api.put(url, object)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new ComponentSchemaApi()
