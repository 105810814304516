import { BaseApi } from "./BaseApi"

/**
 * Create a Location API wrapper around axios.
 */
class LocationApi extends BaseApi {
  constructor () {
    super()
    this.type = "location"
  }

  /**
   * Create one Location and saves it
   *
   * @param { Object } object - Object to update database with
   */
  createBase(accountId) {
    const url = this.baseDomain + "/" + accountId + "/location-create"

    // if (this.type !== 'account' && accountId) {
    //   this.buildUrl(null, accountId)
    //   url = this.baseUrl
    // }

    return this.api.post(url, {})
  }

  all(ids, relations, where, take, sortColumn, sortOrder = "ASC") {
    // override the default 20 from the BaseApi class
    if (!take) {
      take = 200
    }
    return super.all(ids, relations, where, take, sortColumn, sortOrder = "ASC")
  }

  /**
   * Update url slug for location
   */
  updateUrlSlug(accountId, locationId, urlSlug) {
    const url = this.baseDomain + `/${ accountId }/location/${ locationId }/update-url-slug`
    return this.api.post(url, { url_slug: urlSlug, id: locationId })
  }
  updateRhsUrlSlug(accountId, locationId, urlSlug) {
    const url = this.baseDomain + `/${ accountId }/location/${ locationId }/update-rhs-url-slug`
    return this.api.post(url, { rhs_location_id: urlSlug, id: locationId })
  }

  restore(location, accountId) {
    try {
      const url = this.baseDomain + `/${ accountId }/location/${ location.id }/restore`
      // Put URL Back to Original
      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new LocationApi()
