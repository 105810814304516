import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a website API wrapper around axios.
 */
class SitelinkApi extends BaseApi {
  constructor() {
    super()
    this.type = "importer"
  }

  /**
   * Import client account to environment
   *
   * @param { Object } account
   * @param { string } endpoint
   */
  importClient(account, endpoint, checklist) {
    try {
      let url = this.baseDomain

      // Attach path and location
      // url += `/import-client/${account.id}`
      url += `/import-client/${account.id}`

      // Create body request
      const bodyRequest = {
        client_code: account.client_code,
        endpoint: endpoint,
        checklist,
      }
      return this.api.post(url, bodyRequest)
    } catch (error) {
      console.error(error)
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new SitelinkApi()
