/**
 * Using the Factory Method Pattern for the API.
 *
 * - https://en.wikipedia.org/wiki/Factory_method_pattern
 * - https://medium.com/canariasjs/vue-api-calls-in-a-smart-way-8d521812c322
 */

import AccountApi from "./AccountApi"
import AmenityListApi from "./AmenityListApi"
import LocationApi from "./LocationApi"
import MediaApi from "./MediaApi"
import TagApi from "./TagApi"
import WebsiteApi from "./WebsiteApi"
import SettingApi from "./SettingApi"
import GlobalStylesApi from "./GlobalStylesApi"
import NavMenuApi from "./NavMenuApi"
import PageApi from "./PageApi"
import BlogApi from "./BlogApi"
import EmailNotificationEventApi from "./EmailNotificationEventApi"
import EmailNotificationApi from "./EmailNotificationApi"
import ContactApi from "./ContactApi"
import ContentApi from "./ContentApi"
import HourApi from "./HourApi"
import AmenityApi from "./AmenityApi"
import UnitApi from "./UnitApi"
import ReviewsApi from "./ReviewsApi"
import BrightLocalApi from "./BrightLocalApi"
import DiscountApi from "./DiscountApi"
import ComponentSchemaApi from "./ComponentSchemaApi"
import ComponentApi from "./ComponentApi"
import ComponentBreakdownApi from "./ComponentBreakdownApi"
import RowApi from "./RowApi"
import FormApi from "./FormApi"
// import SectionApi from './SectionApi'
import SectionSchemaApi from "./SectionSchemaApi"
import PropSchemaApi from "./PropSchemaApi"
import PageSchemaApi from "./PageSchemaApi"
import ThemeSchemaApi from "./ThemeSchemaApi"
import SchemaTagApi from "./SchemaTagApi"
import EventApi from "./EventApi"
import SitelinkApi from "./SitelinkApi"
import UserApi from "./UserApi"
import ImporterApi from "./ImporterApi"

const apis = {
  account: AccountApi,
  location: LocationApi,
  media: MediaApi,
  tag: TagApi,
  website: WebsiteApi,
  globalStyles: GlobalStylesApi,
  navMenu: NavMenuApi,
  blog: BlogApi,
  form: FormApi,
  user: UserApi,
  setting: SettingApi,
  amenityList: AmenityListApi,
  emailNotificationEvent: EmailNotificationEventApi,
  emailNotification: EmailNotificationApi,
  contact: ContactApi,
  content: ContentApi,
  page: PageApi,
  hour: HourApi,
  amenity: AmenityApi,
  sitelink: SitelinkApi,
  reviews: ReviewsApi,
  brightLocal: BrightLocalApi,
  unit: UnitApi,
  event: EventApi,
  discount: DiscountApi,
  componentSchema: ComponentSchemaApi, // do we need this in nuxt?
  component: ComponentApi,
  componentBreakdown: ComponentBreakdownApi,
  // section: SectionApi,
  sectionSchema: SectionSchemaApi,
  propSchema: PropSchemaApi,
  pageSchema: PageSchemaApi,
  themeSchema: ThemeSchemaApi,
  schemaTag: SchemaTagApi,
  row: RowApi,
  importer: ImporterApi,
  // other repositories ...
}

export const ApiFactory = {
  get: (name) => apis[name],
}
