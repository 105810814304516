import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a website API wrapper around axios.
 */
class SitelinkApi extends BaseApi {
  constructor() {
    super()
    this.type = "sitelink"
  }

  buildUrl(accountId) {
    let url = this.baseDomain
    url += `/${this.type}/${accountId}`
    return url
  }

  updateCache(params) {
    try {
      let url = this.baseDomain
      // Attach path and location
      url += `/${this.type}/${params.accountId}/update-cache-all`
      if (!params.body) return this.api.post(url, { timeout: 30000 })
      else return this.api.post(url, params.body, { timeout: 30000 })
    } catch (error) {
      console.error(error)
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new SitelinkApi()
