import { BaseApi } from './BaseApi'

/**
 * Create a Contact API wrapper around axios.
 */
class AmenityApi extends BaseApi {
  constructor() {
    super()
    this.type = 'amenity'
    this.buildUrl(this.type)
  }
}

export default new AmenityApi()
