import axios from "axios"
import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a Section API wrapper around axios.
 */
class SectionSchemaApi extends BaseApi {
  constructor() {
    super()
    this.type = "section-schema"
    this.buildUrl(this.type)
  }

  saveCustomSectionSchemaApi(data) {
    return new Promise(async (resolve, reject) => {
      try {
        this.buildUrl(this.type)
        let url = this.baseUrl

        // Attach item ID to url
        url += `/save-custom`
        const file = await toBase64(data.file)
        const params = {
          name: data.name,
          description: data.description,
          id: data.id,
          file,
          file_name: data.file.name,
          tags: data.tags,
        }

        if (data.parent_id) {
          params.parent_id = data.parent_id
        }

        const res = await axios.post(url, params)
        return resolve(res.data)
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  }
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })

export default new SectionSchemaApi()
