import { config } from "@/config"
import Vue from "vue"

export function generateApiUrl(route) {
  const base = Vue.$config.api_url
  const prefix = Vue.$config.api_version
  // Set url string
  const url = `${ base }/${ prefix }/${ route }`

  return url
}
