import * as uuid from "uuid/v1"
import * as _ from "lodash"

// same as buildNewPropsFromSchema() below, but without the recursion
export function buildSchemas(newSchemas) {
  return newSchemas.map(schema => _.cloneDeep(schema))
}

// same as updatePropsFromSchema() below, but without the recursion
export function updateSchemas(updatedSchemas, propSchemas) {
  for (let schema of updatedSchemas) {
    let propSchema = propSchemas.find(p => schema.id === p.id)
    if (propSchema) {
      propSchema = _.cloneDeep(schema)
    }
    else {
      // we're updating a prop child that doesn't have this schema yet
      const newPropSchema = buildSchemas([schema])
      propSchemas = propSchemas.concat(newPropSchema)
      console.info(`added child prop schema: ${ schema.label }`)
    }
  }

  return propSchemas
}

/**
 * recursively build a new prop or prop_schema object from a schema
 * note: this is only recursive since it needs to check all children (repeaters)
 */
export function buildNewPropsFromSchema(newSchemas) {
  return newSchemas.map(schema => {
    try {
      var schemaDefault = JSON.parse(schema.default)
    } catch (error) {
      schemaDefault = schema.default
    }

    /**
     * Add image default empty object value
     *
     * The PugImg component doesn't like an empty string for the image prop value,
     * so we add an empty object default.
     */
    if (!schemaDefault && schema.type && ["image", "image-picker"].includes(schema.type)) {
      schemaDefault = {}
    }

    let newProp = {
      id: uuid(),
      schema: _.cloneDeep(schema),
      value: schemaDefault,
      is_schema: false,
      children: []
    }

    if (schema && schema.children && schema.children.length > 0) {
      // Build children props recursively
      newProp.children = buildNewPropsFromSchema(schema.children)
    }

    return newProp
  })
}

/**
 * recursively build a new prop or prop_schema object from a schema
 * note: this is only recursive since it needs to check all children (repeaters)
 */
export function updatePropsFromSchema(updatedSchemas, props) {
  for (let schema of updatedSchemas) {
    const prop = props.find(p => schema.id === p.schema.id)
    if (prop) {
      prop.schema = _.cloneDeep(schema)
      // Update children props
      if (schema.children && schema.children.length > 0) {
        if (prop.children && prop.children.length > schema.children.length) {
          // there is a child prop that no longer exists and needs to be removed
          _.remove(prop.children, child => {
            const index = _.findIndex(schema.children, sc => sc.id === child.schema.id)
            if (index < 0) {
              console.info(`removed child prop: ${ child.schema.label }`)
              return true
            }
            else {
              return false
            }
          })
        }

        // recursively update children
        prop.children = updatePropsFromSchema(schema.children, prop.children)
      }
    }
    else {
      // we're updating a prop child that doesn't have this schema yet
      const newProp = buildNewPropsFromSchema([schema])
      props = props.concat(newProp)
      console.info(`added child prop: ${ schema.label }`)
    }
  }

  return props
}
