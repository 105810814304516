import { BaseApi } from './BaseApi'
import { generateApiUrl } from '@/utils/helpers/generate-api-url'

/**
 * Create a Media API wrapper around axios.
 */
class MediaApi extends BaseApi {
  constructor() {
    super()
    this.type = 'media'
  }

  buildUploadUrl(accountId) {
    return generateApiUrl(`${accountId}/image-upload`)
  }

  buildGlobalUrl(icons = null) {
    return generateApiUrl(`media/global`)
  }

  uploadImage(image, accountId) {
    const baseUrl = this.buildUploadUrl(accountId)

    return this.api.post(baseUrl, {
      images: image
    })
  }

  getGlobalMedia(data) {
    var baseUrl = this.buildGlobalUrl()
    // console.log(baseUrl)
    if (data && data.icons) {
      baseUrl += '?icons=icons'
    }
    if (data && data.stock) {
      baseUrl += '?stock=stock'
    }

    return this.api.get(baseUrl)
  }
}

export default new MediaApi()
