import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a ComponentBreakdown API wrapper around axios.
 */
class ComponentBreakdownApi extends BaseApi {
  constructor (opts) {
    super(Object.assign({ type: "component-breakdown" }, opts))
    this.buildUrl(this.type)
  }

  /**
   * Returns a list of component graph info
   */
  getComponents(options) {
    try {
      let url = `${ this.baseUrl }`

      // Add url params
      if (Object.keys(options).length > 0) {
        const params = new URLSearchParams(options)
        url += `?${ params.toString() }`
      }

      return this.$axios.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Returns a breakdown of a single component across accounts
   */
  getComponentBreakdown(id) {
    try {
      let url = `${ this.baseUrl }/${ id }`

      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new ComponentBreakdownApi()
