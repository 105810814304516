import { BaseApi } from './BaseApi'
import axios from 'axios'

/**
 * Create a website API wrapper around axios.
 */
class SettingApi extends BaseApi {
  constructor() {
    super()
    this.type = 'setting'
  }

  getSettingsByLocation(accountId) {
    this.buildUrl('setting-by-location', accountId)
    const url = this.baseUrl
    this.buildUrl('setting', accountId)
    return this.api.get(url)
  }
}

export default new SettingApi()
