import axios from "axios"
import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a Section API wrapper around axios.
 */
class EventApi extends BaseApi {
  constructor() {
    super()
    this.type = "events"
    this.buildUrl(this.type)
  }

  getRentalsChart(accountId, startDate, endDate, type = "rentals", location_ids) {
    return new Promise(async (resolve, reject) => {
      try {
        this.buildUrl(this.type)
        let url = this.baseUrl

        // Attach item ID to url
        url += `/rentals-chart`
        const body = {
          startDate,
          endDate,
          location_ids: location_ids,
        }

        if (accountId !== null) {
          body["account_id"] = accountId
        }

        if (type != "rentals") {
          body.type = type
        }

        // console.log(url, body)

        const res = await axios.post(url, body)
        return resolve(res.data)
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  }

  getStats(accountId, startDate, endDate, location_ids) {
    return new Promise(async (resolve, reject) => {
      try {
        this.buildUrl(this.type)
        let url = this.baseUrl

        // Attach item ID to url
        url += `/stats`
        const body = {
          startDate,
          endDate,
          location_ids: location_ids,
        }

        if (accountId !== null) {
          body["account_id"] = accountId
        }

        // console.log(url, body)

        const res = await axios.post(url, body)
        return resolve(res.data)
      } catch (error) {
        Vue.rollbar.error(error)
        return reject(error)
      }
    })
  }
}
export default new EventApi()
