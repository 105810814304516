import { BaseApi } from './BaseApi'

/**
 * Create a global styles API wrapper around axios.
 */
class GlobalStylesApi extends BaseApi {
  constructor() {
    super()
    this.type = 'global-style'
  }
}

export default new GlobalStylesApi()
