/**
 * Function to convert an rgba value to hex
 *
 * Source: https://css-tricks.com/converting-color-spaces-in-javascript/#article-header-id-1
 * Demo: https://codepen.io/jkantner/pen/VVEMRK?editors=1010
 */
export function RGBToHex(rgb) {
  const ex = /^rgb\((((((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]),\s?)){2}|((((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5])\s)){2})((1?[1-9]?\d)|10\d|(2[0-4]\d)|25[0-5]))|((((([1-9]?\d(\.\d+)?)|100|(\.\d+))%,\s?){2}|((([1-9]?\d(\.\d+)?)|100|(\.\d+))%\s){2})(([1-9]?\d(\.\d+)?)|100|(\.\d+))%))\)$/i
  if (ex.test(rgb)) {
    // choose correct separator
    const sep = rgb.includes(',') ? ',' : ' '
    // turn "rgb(r,g,b)" into [r,g,b]
    rgb = rgb
      .substr(4)
      .split(')')[0]
      .split(sep)

    // convert %s to 0–255
    for (const R in rgb) {
      const r = rgb[R]
      if (r.includes('%')) {
        rgb[R] = Math.round((r.substr(0, r.length - 1) / 100) * 255)
      }
      /* Example:
				75% -> 191
				75/100 = 0.75, * 255 = 191.25 -> 191
				*/
    }

    let r = (+rgb[0]).toString(16)
    let g = (+rgb[1]).toString(16)
    let b = (+rgb[2]).toString(16)

    if (r.length === 1) {
      r = '0' + r
    }
    if (g.length === 1) {
      g = '0' + g
    }
    if (b.length === 1) {
      b = '0' + b
    }

    return '#' + r + g + b
  } else {
    return 'Invalid input color'
  }
}
