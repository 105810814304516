import { BaseApi } from './BaseApi'

/**
 * Create a Component API wrapper around axios.
 */
class ComponentApi extends BaseApi {
  constructor() {
    super()
    this.type = 'component'
    this.buildUrl(this.type, null)
  }
}

export default new ComponentApi()
