import axios from 'axios'
import { BaseApi } from './BaseApi'

/**
 * Create a Prop Schema API wrapper around axios.
 */
class PropSchemaApi extends BaseApi {
  constructor() {
    super()
    this.type = 'prop-schema'
    this.buildUrl(this.type)
  }
}

export default new PropSchemaApi()
