import { BaseApi } from "./BaseApi"
import Vue from "vue"

/**
 * Create a Page API wrapper around axios.
 */
class PageApi extends BaseApi {
  constructor () {
    super()
    this.type = "page"
    this.buildUrl(this.type)
  }

  /**
   * Save item to database
   *
   * @param { Object } object - Object to update database with
   * @param { String } accountId - ID of account
   */
  createLocationPage(object, accountId, websiteId) {
    try {
      this.buildUrl("location-page", accountId)
      const url = this.baseUrl + `?website_id=${ websiteId }`

      return this.api.post(url, object)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  togglePublishStatus(page) {
    try {
      this.buildUrl(`page/${ page.id }/update-published`, null)
      const url = this.baseUrl
      // Put URL Back to Original
      this.buildUrl(this.type, null)
      const object = {
        is_published: page.is_published,
      }

      return this.api.post(url, object)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  /**
   * Returns a super slim page object. Used on website-manage/index
   */
  getPagesReference(website_id) {
    try {
      this.buildUrl(`page/reference`, null)
      const url = this.baseUrl + `?website_id=${ website_id }`
      // Put URL Back to Original
      this.buildUrl(this.type, null)
      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  getPageHistory(page) {
    try {
      this.buildUrl(`page/${ page.id }/history`, null)
      const url = this.baseUrl
      // Put URL Back to Original
      this.buildUrl(this.type, null)
      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  getPageHistoryItem(page, pageHistory) {
    try {
      this.buildUrl(`page/${ page.id }/history/${ pageHistory.id }`, null)
      const url = this.baseUrl
      // Put URL Back to Original
      this.buildUrl(this.type, null)
      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }

  restore(page) {
    try {
      this.buildUrl(`page/${ page.id }/restore`, null)
      const url = this.baseUrl
      // Put URL Back to Original
      this.buildUrl(this.type, null)
      return this.api.get(url)
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new PageApi()
