import { BaseApi } from './BaseApi'

/**
 * Create a Contact API wrapper around axios.
 */
class ContactApi extends BaseApi {
  constructor () {
    super()
    this.type = 'contact'
  }
}

export default new ContactApi()
