import Vue from "vue"
import { BaseApi } from "./BaseApi"

/**
 * Create a Form API wrapper around axios.
 */
class FormApi extends BaseApi {
  constructor() {
    super()
    this.type = "forms"
    this.buildUrl(this.type)
  }

  /**
   * Delete one item from database
   */
  batchRemove(body, accountId) {
    try {
      let url = this.baseUrl

      if (this.type !== "account" && accountId) {
        this.buildUrl(null, accountId)
        url = this.baseUrl
      }

      url = url + "-batch-remove"
      return this.api.delete(url, { data: body })
    } catch (error) {
      Vue.rollbar.error(error)
      return error
    }
  }
}

export default new FormApi()
